/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/vue-snotify@3.2.1/styles/material.min.css
 * - /npm/vue-directive-tooltip@1.6.3/dist/vueDirectiveTooltip.css
 * - /npm/vue-search-select@2.9.3/dist/VueSearchSelect.css
 * - /npm/vue-simple-suggest@1.10.1/dist/styles.css
 * - /npm/vue2-datepicker@3.7.0/index.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
